import React from "react"
import { Link } from "gatsby"
import "./style.scss"

const Blogs = ({ blogs }) => {
  return (
    <ul className="blogs">
      {blogs.map((node, index) => {
        const {
          id,
          excerpt,
          frontmatter: { slug, date, title },
        } = node
        return (
          <article key={id}>
            <Link to={`/blog/${slug}`}>
              {title}
              {excerpt}
              {date}
            </Link>
          </article>
        )
      })}
    </ul>
  )
}

export default Blogs
