import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Blogs from "../components/Blogs"
import Seo from "../components/Seo"

const BlogPage = ({ data }) => {
  const { nodes } = data.allMdx
  return (
    <Layout>
      <Blogs blogs={nodes} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx {
      nodes {
        id
        excerpt
        frontmatter {
          date
          title
          slug
        }
      }
    }
  }
`

export const Head = () => <Seo title="My Blog Posts" />

export default BlogPage
